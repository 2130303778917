/**
 * @module Actions/Pages
 * @desc Pages Actions
 */
import { ActionTypes } from 'constants/index';
import { FormService } from 'modules/api/index';

export const clearReduxStore = () => dispatch => {
  dispatch({ type: ActionTypes.CLEAR_REDUX_STORE });
};
export const getAllForms = (appId) => dispatch => {
  dispatch({ type: ActionTypes.GET_FORMS_LOADING, appId });
};
export const getFormByName = (formName, submissionId, appId) => dispatch => {
  dispatch({ type: ActionTypes.GET_FORM_BY_NAME_LOADING, formName, submissionId, appId });
};
export const getSubmittedDataBySubmissionId = (id, formName, appId) => dispatch => {
  dispatch({ type: ActionTypes.GET_SUBMITTED_DATA_BY_ID_LOADING, id, formName });
};
export const saveSubmittedData = (data, files) => dispatch => {
  dispatch({ type: ActionTypes.SAVE_SUBMITTED_DATA_LOADING, data, files })
}
export const updateSubmittedData = (data, files) => dispatch => {
  dispatch({ type: ActionTypes.UPDATE_SUBMITTED_DATA_LOADING, data, files })
}

export const clearSubmittedData = () => dispatch => {
  dispatch({ type: ActionTypes.CLEAN_SUBMITTED_DATA })
}
export const updateSelectedFormData = (cardIndex, outerIndex) => dispatch => {
  dispatch({ type: ActionTypes.UPDATE_SELECTED_FORM, cardIndex, outerIndex })
}

export const getCognitiveSearchData = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_COGNITIVE_SEARCH_DATA, payload: data });
}

export const getModelData = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_MODAL_DATA, payload: data });
};

export const getModelDataDropDown = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_MODAL_DROP_DOWN_DATA, payload: data });
};

export const getCognitiveSearchV1Data = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_COGNITIVE_SEARCH_DATA_V1, payload: data });
}

export const saveAdvancedSearchData = (data) => dispatch => {
  dispatch({ type: ActionTypes.SAVE_SMART_SEARCH_DATA, payload: data });
}

export const getAllSavedSearchData = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_ALL_SMART_SEARCH_DATA, payload: data });
}

export const uploadPDF = (data) => dispatch => {
  dispatch({ type: ActionTypes.SUBMIT_CONTRACT_PDF_LOADING, payload: data });
};

export const downloadContractPDFAction = (data) => dispatch => {
  dispatch({ type: ActionTypes.DOWNLOAD_PDF_ACTION, payload: data });
};

export const deleteContractPDFAction = (data) => dispatch => {
  dispatch({ type: ActionTypes.DELETE_CONTRACT_PDF, payload: data });
};

export const getContractDataById = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_DOCUMENT_DATA, payload: data });
};

export const getLineItemsTablesData = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_LINEITEM_TABLES_DATA, payload: data });
};

export const getDocumentTablesDataById = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_DOCUMENT_TABLES_DATA, payload: data });
};

export const createDocumentTableData = (data) => dispatch => {
  dispatch({ type: ActionTypes.CREATE_DOCUMENT_TABLES_DATA, payload: data });
}

export const updateDocumentTableData = (data) => dispatch => {
  dispatch({ type: ActionTypes.UPDATE_DOCUMENT_TABLES_DATA, payload: data });
}

export const deleteDocumentTableData = (data) => dispatch => {
  dispatch({ type: ActionTypes.DELETE_DOCUMENT_TABLE_DATA, payload: data });
}

export const hideSuccessMsg = () => dispatch => {
  dispatch({ type: ActionTypes.HIDE_SUCCESS_MSG });
};

export const clearUploadPDFData = () => dispatch => {
  dispatch({ type: ActionTypes.CLEAR_UPLOAD_PDF_DATA });
};

export const downloadSheet = (data) => dispatch => {
  dispatch({ type: ActionTypes.DOWNLOAD_SHEET, payload: data });
}
export const updateDataModelData = (data) => dispatch => {
  dispatch({ type: ActionTypes.UPDATE_DATA_MODAL_DATA, payload: data });
}
export const clearSuccessMsg = (data) => dispatch => {
  dispatch({ type: ActionTypes.CLEAR_SUCCESS_MSG, payload: data });
}

export const clearPDFDuplicateError = (data) => dispatch => {
  dispatch({ type: ActionTypes.CLAER_SUBMIT_CONTRACT_PDF_DUPLICATE_ERROR, payload: data });
}

export const createDocument = (data) => dispatch => {
  dispatch({ type: ActionTypes.CREATE_DOCUMENT, payload: data });
}

export const getDataModelById = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_DATA_MODEL_BY_ID, payload: data });
}

export const getLineData = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_LINE_DATA_BY_ID, payload: data });
}

export const getBarData = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_BAR_DATA_BY_ID, payload: data });
}

export const updateDataModel = (data) => dispatch => {
  dispatch({ type: ActionTypes.UPDATE_DATA_MODEL_BY_ID, payload: data });
}

export const clearReduxDataOfCurrentComponent = (data) => dispatch => {
  dispatch({ type: ActionTypes.CLEAR_REDUX_DATA_OF_COMPONENT, payload: data });
}

export const saveDataForTraining = (data) => dispatch => {
  dispatch({ type: ActionTypes.SAVE_DATA_FOR_TRAINING, payload: data });
}

export const getTopSuggestions = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_TOP_SUGGESTIONS, payload: data });
}

export const onSearch = (data) => dispatch => {
  dispatch({ type: ActionTypes.ON_SEARCH_TEXT, payload: data });
}

export const getTasks = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_TASKS, payload: data });
}
export const descriptionUpdated = (data) => dispatch => {
  dispatch({ type: ActionTypes.TASK_DATA_DESCRIPTION_LOADING, payload: data });
}

export const getTaskData = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_TASK_DATA, payload: data });
}

export const updateTaskData = (data) => dispatch => {
  dispatch({ type: ActionTypes.UPDATE_TASK_DATA_LOADING, payload: data });
}

export const deleteTaskData = (data) => dispatch => {
  dispatch({ type: ActionTypes.DELETE_TASK_DATA, payload: data });
}

export const getMembersList = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_MEMBERS_LIST, payload: data });
}

export const getAllMembersList = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_ALL_MEMBERS_LIST, payload: data });
}

export const processAgain = (data) => dispatch => {
  return dispatch({ type: ActionTypes.PROCESS_AGAIN, payload: data })
}

export const getAuditTrailsData = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_AUDIT_TRAILS_DATA, payload: data });
}

export const getOthersAuditTrailsData = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_OTHERS_AUDIT_TRAILS_DATA, payload: data });
}

export const getHistoryData = (data) => dispatch => {
  return dispatch({ type: ActionTypes.GET_HISTORY_DATA, payload: data });
}

export const bulkUploadPDF = (data) => dispatch => {
  return FormService.bulkUploadPDF(data)
  // dispatch({ type: ActionTypes.BULK_UPLOAD_PDF, payload: data });
};

export const bulkUploadPDFFinal = (data) => dispatch => {
  return FormService.bulkUploadPDFFinal(data)
  // dispatch({ type: ActionTypes.BULK_UPLOAD_PDF_MERGE, payload: data });
};

export const uploadPDFAsync = (data) => dispatch => {
  return FormService.uploadContractPDFData(data)
};

export const serviceBusAction = (data) => dispatch => {
  dispatch({ type: ActionTypes.SERVICE_BUS_DATA, payload: data });
};

export const serviceBusStatusUpdateTileList = (data) => dispatch => {
  dispatch({ type: ActionTypes.SERVICE_BUS_TITLE_LIST_STATUS, payload: data });
};

export const changeAssigneeRequest = (data) => dispatch => {
  dispatch({ type: ActionTypes.ASSIGNEE_CHANGE, payload: data })
}

export const completeReviewAction = (data) => dispatch => {
  dispatch({ type: ActionTypes.COMPLETE_REVIEW, payload: data })
}

export const rejectReviewAction = (data) => dispatch => {
  dispatch({ type: ActionTypes.REJECT_REVIEW, payload: data })
}

export const createMember = (data) => dispatch => {
  dispatch({ type: ActionTypes.CREATE_MEMBER, payload: data });
}

export const getRolesMembers = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_ROLES_MEMBERS, payload: data });
}

export const getGroups = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_GROUPS, payload: data });
}

export const deleteMember = (data) => dispatch => {
  dispatch({ type: ActionTypes.DELETE_MEMBER, payload: data });
}

export const getWorkflow = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_WORKFLOW, payload: data });
}

export const executeWorkFlow = (data) => dispatch => {
  dispatch({ type: ActionTypes.EXECUTE_WORKFLOW, payload: data });
}

export const initWorkFlow = (data) => dispatch => {
  dispatch({ type: ActionTypes.INIT_WORKFLOW, payload: data });
}

export const getChargeCodes = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_CHARGE_CODES, payload: data });
}

export const getNextRespondent = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_NEXT_RESPONDENT, payload: data });
}

export const getCurrentStepRespondents = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_CURRENT_STEP_RESPONDENT, payload: data });
}

export const getNextTasks = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_NEXT_TASKS, payload: data });
}

export const getAppMngmtConfig = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_APP_CONFIG, payload: data });
}

export const saveAppMngmtConfig = (data) => dispatch => {
  dispatch({ type: ActionTypes.SAVE_APP_CONFIG, payload: data });
}

export const createGroup = (data) => dispatch => {
  dispatch({ type: ActionTypes.GROUPS_CREATE, payload: data });
}

export const deleteGroup = (data) => dispatch => {
  dispatch({ type: ActionTypes.DELETE_GROUPS, payload: data });
}

export const getChargeCode = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_CHARGE_CODE, payload: data });
}

export const createChargeCode = (data) => dispatch => {
  dispatch({ type: ActionTypes.CREATE_CHARGE_CODE, payload: data });
}

export const deleteChargeCode = (data) => dispatch => {
  dispatch({ type: ActionTypes.DELETE_CHARGE_CODE, payload: data });
}

export const archivedData = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_ARCHIVED_DATA, payload: data });
};

export const executeWorkFlowReject = (data) => dispatch => {
  dispatch({ type: ActionTypes.REJECT_WORKFLOW, payload: data });
}

export const executeRejectToQa = (data) => dispatch => {
  dispatch({ type: ActionTypes.REJECT_TO_QA, payload: data });
}

export const getReviewInfo = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_REVIEW_INFO, payload: data });
}

export const getPoInfoFromExternalSource = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_PO_INFO_FROM_EXTERNAL_SOURCE, payload: data });
}

export const handleSageIntacct = (data) => dispatch => {
  dispatch({ type: ActionTypes.SAGE_INTACCT, payload: data });
}

export const getDeletedDoc = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_DELETED_DOCUMENT, payload: data });
};

export const getDelegations = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_DELEGATIONS, payload: data });
}

export const getDelegation = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_DELEGATION, payload: data });
}

export const createDelegation = (data) => dispatch => {
  dispatch({ type: ActionTypes.CREATE_DELEGATION, payload: data });
}

export const deleteDelegation = (data) => dispatch => {
  dispatch({ type: ActionTypes.DELETE_DELEGATION, payload: data });
}

export const getTickets = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_TICKETS, payload: data });
}

export const createTickets = (data) => dispatch => {
  dispatch({ type: ActionTypes.CREATE_TICKETS, payload: data });
}

export const deleteTicket = (data) => dispatch => {
  dispatch({ type: ActionTypes.DELETE_TICKETS, payload: data });
}

export const restoreDeleteDoc = (data) => dispatch => {
  dispatch({ type: ActionTypes.RESTORE_DELETE_DOC, payload: data });
}

export const restoreOthersDeletedDoc = (data) => dispatch => {
  dispatch({ type: ActionTypes.RESTORE_OTHERS_DELETED_DOC, payload: data });
}

export const terminateMember = (data) => dispatch => {
  dispatch({ type: ActionTypes.TERMINATE_MEMBER, payload: data });
}

export const getAllOtherDocs = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_ALL_OTHER_DOC, payload: data });
}

export const getAllOthersDeletedDocs = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_ALL_OTHER_DELETED_DOC, payload: data });
}

export const downloadAllOtherDocs = (data) => dispatch => {
  dispatch({ type: ActionTypes.DOWNLOAD_ALL_OTHER_DOCS, payload: data });
}

export const deleteOtherDocs = (data) => dispatch => {
  dispatch({ type: ActionTypes.DELETE_ALL_OTHER_DOCS, payload: data });
}

export const getPDFForOtherDoc = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_PDF_FOR_OTHER_DOC, payload: data });
};

export const getOthersDeletedDoc = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_OTHERS_DELETED_DOC, payload: data });
};

export const otherDocMove = (data) => dispatch => {
  dispatch({ type: ActionTypes.OTHER_DOC_MOVE, payload: data });
};

export const moveToDuplicates = (data) => dispatch => {
  dispatch({ type: ActionTypes.MOVE_TO_DUPLICATES, payload: data });
};

export const addComment = (data) => dispatch => {
  dispatch({ type: ActionTypes.ADD_COMMENT, payload: data });
};

export const addNewEntriesByDataModel = (data) => dispatch => {
  dispatch({ type: ActionTypes.ADD_NEW_ENTRY_BY_DATAMODELS, payload: data });
};

export const bulkAssign = (data) => dispatch => {
  dispatch({ type: ActionTypes.BULK_ASSIGN, payload: data })
}

export const getGroupsByMemberId = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_GROUPS_BY_MEMBER_ID, payload: data });
};

export const onUploadData = (data) => dispatch => {
  dispatch({ type: ActionTypes.UPLOAD_DATA, payload: data });
};

export const getSupportingDocuments = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_SUPPORTING_dOCUMENTS, payload: data });
}

export const finalDocument = (data) => dispatch => {
  dispatch({ type: ActionTypes.FINAL_DOCUMENT, payload: data });
};

export const duplicatesGetRequest = (data) => dispatch => {
  dispatch({ type: ActionTypes.DUPLICATES_GET_REQUEST, payload: data })
}

export const getDuplicateDocById = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_DUPLICATE_DOC, payload: data })
}

export const duplicatesMoveRequest = (data) => dispatch => {
  dispatch({ type: ActionTypes.DUPLICATES_MOVE_REQUEST, payload: data })
}

export const splitPDF = (data) => dispatch => {
  dispatch({ type: ActionTypes.SPLIT_PDF, payload: data })
}

export const uploadSupportDocument = (data) => dispatch => {
  dispatch({ type: ActionTypes.UPLOAD_SUPPORT_DOCUMENT, payload: data })
}

export const getVISData = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_VIS_DATA, payload: data })
} 

export const lockDocument = (data) => dispatch => {
  dispatch({ type: ActionTypes.LOCK_DOCUMENT, payload: data })
}

export const unlockDocument = (data) => dispatch => {
  dispatch({ type: ActionTypes.UNLOCK_DOCUMENT, payload: data })
}

export const getWorkflowById = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_WORKFLOW_BY_ID, payload: data })
}

export const setDataToLocalDB = (data) => dispatch => {
  dispatch({ type: ActionTypes.SET_DATA_TO_DB, payload: data })
}

export const clearDataFromLocalDB = (data) => dispatch => {
  dispatch({ type: ActionTypes.CLEAR_DATA_FROM_DB, payload: data })
}

export const fetchmasterDataForDataelementUsingRules = (data) => dispatch => {
  dispatch({ type: ActionTypes.FETCH_MASTER_DATA, payload: data });
};

export const fetchProcessDashboard1Data = (data) => dispatch => {
  dispatch({ type: ActionTypes.FETCH_PROCESS_DASHBOARD_1_DATA, payload: data });
}

export const fetchProcessDashboard2Data = (data) => dispatch => {
  dispatch({ type: ActionTypes.FETCH_PROCESS_DASHBOARD_2_DATA, payload: data });
}

export const setSelectedChartItems = (data) => dispatch => {
  dispatch({ type: ActionTypes.SELECTED_DASHBOARD_CHART_ITEMS, payload: data });
}

export const createAmendment = (data) => dispatch => {
  dispatch({ type: ActionTypes.CREATE_AMENDMENT, payload: data });
};

export const getAmendments = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_AMENDMENTS, payload: data });
};

export const getNotDuplicateAPI = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_NOTDUPLICATE, payload: data });
};

export const createContractHierarchy = (data) => dispatch => {
  dispatch({ type: ActionTypes.CREATE_CONTRACT_HIERARCHY, payload: data });
};

export const getContractHierarchys = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_CONTRACT_HIERARCHYS, payload: data });
};

export const getDocumentHierarchys = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_DOCUMENT_HIERARCHYS, payload: data });
};
export const updateDocHierarchys = (data) => dispatch => {
  dispatch({ type: ActionTypes.UPDATE_DOCUMENT_HIERARCHYS, payload: data });
};

export const getDocumentsForHierarchys = (data) => dispatch => {
  dispatch({ type: ActionTypes.GET_DOCUMENTS_FOR_HIERARCHYS, payload: data });
};

export const saveDocumentHierarchy = (data) => dispatch => {
  dispatch({ type: ActionTypes.SAVE_DOCUMENT_HIERARCHY, payload: data });
};

export const createNewDocumentPrompt = (data) => dispatch => {
  dispatch({ type: ActionTypes.SAVE_DOCUMENT_PROMPT, payload: data });
};

export const getDocumentQuestions = (data) => dispatch => {
  dispatch({ type: ActionTypes.FETCH_DOCUMENT_QUESTIONS, payload: data });
};

export const emailSendNotification = (payload) => dispatch => {
  dispatch({ type: ActionTypes.EMAIL_SEND_NOTIFICATIONS, payload });
};

export const fetchDocumentDataFromTargetDataModel = (data) => dispatch => {
  dispatch({ type: ActionTypes.FETCH_DOCUMENT_DATA_FROM_TARGET_DATAMODEL, payload:data });
};

export const fetchDocumentDataToSuportMapping = (data) => dispatch => {
  dispatch({ type: ActionTypes.FETCH_DOCUMENT_DATA_TO_SUPPORT_MAPPING, payload:data });
};

export const updateMappingData = (data) => dispatch => {
  dispatch({ type: ActionTypes.UPDATE_MAPPING_DATA, payload:data });
};

export const fetchDocumentDataToSuportThreeWayMatch = (data) => dispatch => {
  dispatch({ type: ActionTypes.FETCH_DOCUMENT_DATA_TO_SUPPORT_THREE_WAY_MATCH, payload:data });
};

export const getDocumentAnnotations = (payload) => dispatch => {
  dispatch({ type: ActionTypes.GET_DOCUMENT_ANNOTATIONS, payload });
};

export const createOpportunity = (payload) => dispatch => {
  dispatch({ type: ActionTypes.CREATE_OPPORTUNITY, payload });
};

export const getAllOpportunities = (payload) => dispatch => {
  dispatch({ type: ActionTypes.GET_ALL_OPPORTUNITIES, payload });
};

export const getSingleOpportunity = (payload) => dispatch => {
  dispatch({ type: ActionTypes.GET_SINGLE_OPPORTUNITY, payload });
};

export const deleteOpportunity = (payload) => dispatch => {
  dispatch({ type: ActionTypes.DELETE_OPPORTUNITY, payload });
};

export const updateOpportunity = (payload) => dispatch => {
  dispatch({ type: ActionTypes.UPDATE_OPPORTUNITY, payload });
};

export const createActionForOpportunity = (payload) => dispatch => {
  dispatch({ type: ActionTypes.CREATE_ACTION_FOR_OPPORTUNITY, payload });
};

export const getAllActionsOfOpportunity = (payload) => dispatch => {
  dispatch({ type: ActionTypes.GET_ALL_ACTION_OF_OPPORTUNITY, payload });
};
/**
 * Creates an action to initiate the upload of an attachment for an opportunity.
 * Dispatches an action of type `UPLOAD_ATTACHEMENT_OF_OPPORTUNITY` with the provided payload.
 * 
 * @param {Object} payload - The payload containing data for the upload.
 * @param {string} payload.dataUniqueId - A unique identifier for the data being uploaded.
 * @param {FormData} payload.formData - The form data object containing the file(s) to be uploaded.
 * @returns {Function} - A function that dispatches the action to the Redux store.
 */
export const uploadAttachmentOpportunity = (payload) => dispatch => {
  dispatch({ type: ActionTypes.UPLOAD_ATTACHEMENT_OF_OPPORTUNITY, payload });
};

export const getSingleActionOfOpportunity = (payload) => dispatch => {
  dispatch({ type: ActionTypes.GET_SINGLE_ACTION_OF_OPPORTUNITY, payload });
};

export const updateSingleActionOfOpportunity = (payload) => dispatch => {
  dispatch({ type: ActionTypes.UPDATE_SINGLE_ACTION_OF_OPPORTUNITY, payload });
};

export const deleteSingleActionOfOpportunity = (payload) => dispatch => {
  dispatch({ type: ActionTypes.DELETE_SINGLE_ACTION_OF_OPPORTUNITY, payload });
};
/**
 * Creates an action to initiate the deletion of attachments for an opportunity.
 * Dispatches an action of type `DELETE_ATTACHMENTS_FOR_OPPORTUNITY` with the provided payload.
 * 
 * @param {Object} payload - The payload containing data for the deletion.
 * @param {string} payload.dataUniqueId - A unique identifier for the data being deleted.
 * @returns {Function} - A function that dispatches the action to the Redux store.
 */
export const deleteAttachmentsForOpportunity = (payload) => dispatch => {
  dispatch({ type: ActionTypes.DELETE_ATTACHMENTS_FOR_OPPORTUNITY, payload });
};

export const getDocumentsFromIDsOfOpportunity = (payload) => dispatch => {
  dispatch({ type: ActionTypes.GET_DOCUMENTS_FROM_IDS_OF_OPPORTUNITY, payload });
};

export const resolveIntegrationExceptions = (payload) => dispatch => {
  dispatch({ type: ActionTypes.RESOLVE_INTEGRATION_EXCEPTIONS, payload });
};