import React from 'react'
import moment from "moment"

import { FormControlLabel, InputLabel, Grid, Button, Checkbox, FormHelperText } from '@material-ui/core';
import { makeStyles } from "@material-ui/core"
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { parseISO } from 'date-fns';

import { ChipSelect } from "dsilo-ui-components";

const useStyles = makeStyles((theme) => ({
    paper: {
    },
    gridContainer: {

    },
    InputLabel: {
        marginTop: 20
    },
    TextField: {
        width: '100%',
        '& .MuiInputBase-formControl': {
            height: '43px !important'
        }
    },
    chipSelect: {
        '& input': {
            height: 30,
        },
    },
    status: {
        display: 'contents'
    },
    error: {
        marginLeft: 14,
        color: 'red',
    }
}))

const BasicInfo = (props) => {
    const classes = useStyles();
    let delegation = props.appConfiguration?.system?.delegations
    let hideDelegate = delegation?.hideDelegate || false

    return (
        <Grid container xs={6} spacing={3} className={classes.gridContainer}>
            <Grid item xs={3} sm={3}>
                <InputLabel htmlFor="my-input" className={classes.InputLabel}>User</InputLabel>
            </Grid>
            <Grid item xs={9} sm={9}>
                <ChipSelect
                    value={props.delegationData.user}
                    isSearchable={true}
                    name={'user'}
                    className={classes.chipSelect}
                    textFieldProps={{
                        InputLabelProps: {
                            shrink: true,
                        },
                        variant: 'outlined',
                    }}
                    variant="fixed"
                    options={props.memberList}
                    onChange={(e) => props.basicInfoOnFormChange('user', e)}
                />
                {props.validationError.user && <FormHelperText className={classes.error}>{'Please select a User'}</FormHelperText>}
            </Grid>
            {!hideDelegate && <><Grid item xs={3} sm={3}>
                <InputLabel htmlFor="my-input" className={classes.InputLabel}>Delegate</InputLabel>
            </Grid>
            <Grid item xs={9} sm={9}>
                <ChipSelect
                    value={props.delegationData.delegate}
                    isSearchable={true}
                    name={'delegate'}
                    className={classes.chipSelect}
                    textFieldProps={{
                        InputLabelProps: {
                            shrink: true,
                        },
                        variant: 'outlined',
                    }}
                    variant="fixed"
                    options={props.delegateMemberList}
                    onChange={(e) => props.basicInfoOnFormChange('delegate', e)}
                />
                {props.validationError.delegate && <FormHelperText className={classes.error}>{'Please select a Delegate'}</FormHelperText>}
            </Grid></>}
            <Grid item xs={3} sm={3}>
                <InputLabel htmlFor="my-input" className={classes.InputLabel}>From</InputLabel>
            </Grid>
            <Grid item xs={9} sm={9}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        margin="normal"
                        format="yyyy-MM-dd"
                        name='from'
                        // value={props.delegationData.from}
                        value={parseISO(props.delegationData.from)}
                        onChange={(date) => { props.basicInfoOnFormChange('from', date) }}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </MuiPickersUtilsProvider>
                {props.validationError.from && <FormHelperText className={classes.error}>{'Please select a Valid date'}</FormHelperText>}
            </Grid>
            <Grid item xs={3} sm={3}>
                <InputLabel htmlFor="my-input" className={classes.InputLabel}>To</InputLabel>
            </Grid>
            <Grid item xs={9} sm={9}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        margin="normal"
                        format="yyyy-MM-dd"
                        name='to'
                        // value={props.delegationData.to}
                        value={parseISO(props.delegationData.to)}
                        minDate={props.delegationData.from}
                        onChange={(date) => { props.basicInfoOnFormChange('to', date) }}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </MuiPickersUtilsProvider>
                {props.validationError.to && <FormHelperText className={classes.error}>{'Please select a Valid date'}</FormHelperText>}
            </Grid>
            {!hideDelegate && <Grid item xs={12} sm={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={props.delegationData.assignPendingTasks}
                            onChange={(e) => { props.basicInfoOnFormChange('assignPendingTasks', e.target.checked) }}
                            style={{
                                transform: "scale(1)", paddingRight: 20, marginLeft: 5,
                            }}
                            color="primary"
                            name={'assignPendingTasks'}
                        />}
                    label={'Assign pending tasks'}
                />
            </Grid>}
            <Grid item xs={12} sm={12}>
                <Button style={{ float: 'right' }} variant="contained" color="primary" onClick={props.basicInfoOnSubmit}>{props.delegationData?._id ? "Update" : "Save"}</Button>
            </Grid>
        </Grid>
    )
}

export default BasicInfo